<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
        <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
          <Row type="flex" align="middle">
            <i-col><fm-title :title-text="'入库单入库 - ' + (inorderGroupData ? inorderGroupData.name : '')"></fm-title></i-col>
            <i-col v-if="storehouseData"><Tag size="large" closable @on-close="$router.replace({ name: $route.name })">当前: {{ storehouseData.name }}</Tag></i-col>
          </Row>
        </i-col>
        <i-col>
          <Button v-if="$route.query.back" @click="$router.back()" style="margin-right: 10px;" icon="ios-arrow-back">返回</Button>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:goodsId="{ row }">
          <Tooltip>
            <Tag class="noborder" :color="row.goodsStatusData.color">
              {{ row.goodsName }} ({{ row.goodsUseTypeText }})
            </Tag>
            <template v-slot:content>
              <div>{{ row.goodsStatusData.label }}</div>
              <div>{{ row.goodsGgxh }}</div>
              <div>{{ row.goodsCode }}</div>
            </template>
          </Tooltip>
        </template>
        <!-- <template v-slot:goodsStatus="{ row }">
          <Tag class="noborder" :color="row.goodsStatusData.color">{{ row.goodsStatusData.label }}</Tag>
        </template> -->
        <template v-slot:volume="{ row }">
          {{ row.volume !== null ? (row.volume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:checkVolume="{ row }">
          {{ row.checkVolume !== null ? (row.checkVolume + ' ' + row.goodsUnit) : '-' }}
        </template>
        <template v-slot:action="{ row }">
          <Button :loading="loading.submit" @click="itemDetail(row)" v-if="row.status === 1 || row.status === 2" type="primary" size="small">明细</Button>
          <Button :loading="loading.submit" @click="itemClick(row)" v-if="row.status === 1 || row.status === 2" style="margin-left: 10px;" type="primary" size="small">入库</Button>
        </template>
      </Table>
    </template>
    <template v-slot:footer v-if="$authFunsProxy.manage">
      <Drawer placement="right" title="入库单入库" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <InboundForm v-if="status.form" :storehouseId="currentStorehouseId" ref="form" :data="formData" />
        </DrawerAction>
      </Drawer>
      <Drawer placement="bottom" title="入库单入库明细" :mask-closable="false" closable transfer draggable :height="80" v-model="status.detail">
        <Detail @reload="loadData" accordingType="goods_stock_in_order" :accordingDataId="formData.id" v-if="status.detail" style="height: 100%; margin: 0;padding: 0;" />
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../../components/page/base.vue'
import DrawerAction from '../../../../components/drawer/action.vue'
import request from '../../../../api/index'
import InboundForm from '../../../../components/goods/purchase/form/inbound.vue'
import Detail from '../detail.vue'

export default {
  components: { AppPage, InboundForm, Detail, DrawerAction },
  props: {
    inOrderGroupId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      loading: {
        load: false,
        submit: false,
        end: false
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      formData: null,
      storehouseData: null,
      inorderGroupData: null
    }
  },
  computed: {
    currentStorehouseId () {
      const storehouseId = this.$route.query.storehouseId
      return storehouseId ? Number(storehouseId) : null
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmGoodsHandleTypeList () {
      return this.$store.getters.scmGoodsHandleTypeList
    },
    columns () {
      const columns = [
        { title: '物品', key: 'goodsId', slot: 'goodsId', minWidth: 180 },
        { title: '入库仓库', key: 'storehouseName', minWidth: 100 },
        { title: '计划入库量', key: 'volume', slot: 'volume', minWidth: 130 },
        { title: '完成量', key: 'checkVolume', slot: 'checkVolume', minWidth: 130 }
      ]
      if (this.$authFunsProxy.manage) {
        columns.push({ title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 140 })
      }
      return columns
    }
  },
  watch: {
    inOrderGroupId () {
      if (this.routerName === this.$route.name) {
        this.loadInorderGroup()
        this.refresh()
      }
    },
    currentStorehouseId () {
      if (this.routerName === this.$route.name) {
        this.loadStorehouseData()
        this.refresh()
      }
    }
  },
  methods: {
    async loadInorderGroup () {
      const res = await request.get('/goods_stock_in_order/group', { id: this.inOrderGroupId })
      this.inorderGroupData = res.length ? res[0] : null
    },
    async loadStorehouseData () {
      await this.$store.dispatch('loadStoreHouse')
      this.storehouseData = this.$store.getters.scmStoreHouseList.find(v => v.id === this.currentStorehouseId)
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    itemDetail (item) {
      this.formData = item
      this.status.detail = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (data === false) {
          return this.$Message.warning('请完整填写表单')
        }
        if (data === null) {
          return
        }

        this.loading.submit = true
        await request.post('/goods_stock_in_order/check_and_exe/' + this.formData.id, data)
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    async itemEnd (item) {
      this.loading.end = true
      try {
        await request.post('/goods_stock_in_order/end/' + item.id)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.end = false
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {
        status: '1',
        noGroup: 1
      }

      if (this.currentStorehouseId) {
        params.storehouseId = this.currentStorehouseId
      }

      if (this.inOrderGroupId) {
        params.goodsStockInOrderGroupId = this.inOrderGroupId
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock_in_order', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          item.goodsHandleTypeData = this.scmGoodsHandleTypeList.find(v => v.value === item.goodsHandleType)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
      this.loadInorderGroup()
    }
  },
  mounted () {
    this.loadStorehouseData()
  }
}
</script>
