<style scoped>
</style>

<template>
  <!-- <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange"> -->
  <AppPage>
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <i-col>
              <fm-title :title-text="title" :note-text="note"></fm-title>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <DetailTable @repeal="repeal" :height="contentHeight" :dataList="dataList" :loading="loading.load" />
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../../components/page/base.vue'
import request from '../../../api/index'
import DetailTable from './table/detail.vue'

export default {
  components: { DetailTable, AppPage },
  props: {
    accordingType: { type: String, default: null },
    accordingDataId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      loading: {
        load: false,
        submit: false,
      },
      dataList: [],
      accordingData: null
    }
  },
  computed: {
    title () {
      if (this.accordingType === 'goods_stock_out_order') {
        return '出库单出库'
      } else {
        return '入库单入库'
      }
    },
    note () {
      return this.accordingData ? this.accordingData.goodsName : null
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmStockDetailActionList () {
      return this.$store.getters.scmStockDetailActionList
    }
  },
  watch: {
    accordingDataId () {
      if (this.routerName === this.$route.name) {
        this.loadAccordingData()
      }
    }
  },
  methods: {
    async loadAccordingData () {
      if (this.accordingDataId <= 0) {
        this.accordingData = null
        return
      }
      let data = []
      if (this.accordingType === 'goods_stock_out_order') {
        data = await request.get('/goods_stock_out_order', {
          id: this.accordingDataId
        })
      } else if (this.accordingType === 'goods_stock_in_order') {
        data = await request.get('/goods_stock_in_order', {
          id: this.accordingDataId
        })
      }
      this.accordingData = data.length > 0 ? data[0] : null
    },
    async repeal (item) {
      try {
        if (this.accordingType === 'goods_stock_out_order') {
          await request.delete('/goods_stock_out_order/exe_out/' + this.accordingDataId, {
            goodsStockDetailId: item.id
          })
        } else if (this.accordingType === 'goods_stock_in_order') {
          await request.post('/goods_stock_in_order/back_check_and_exe/' + item.accordingDataId, {
            goodsNotStockId: item.goodsNotStockId
          })
        }
        this.$Message.success('撤销成功')
        this.loadData()
        this.$emit('reload')
      } catch (e) {
        this.$Modal.error({
          title: '撤销失败',
          content: e.message
        })
      }
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      const params = {
        accordingType: this.accordingType
      }

      if (this.accordingDataId) {
        params.accordingDataId = this.accordingDataId
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock/detail', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          item.goodsStatusData = this.scmGoodsStatusList.find(v => v.value === item.goodsStatus)
          item.actionData = this.scmStockDetailActionList.find(v => v.value === item.action)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
      this.loadAccordingData()
    }
  },
  mounted () {
    this.onRowsChange(999)
  }
}
</script>
