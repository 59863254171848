<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
    <FormItem label="仓库\车间" prop="storehouseId">
      <Select v-model="formData.storehouseId" placeholder="请选择仓库\车间">
        <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem v-if="formData.storehouseId" label="仓库单元" prop="storehouseUnitId">
      <UnitChoose :storehouseId="formData.storehouseId" v-model="formData.storehouseUnitId" />
    </FormItem>
  </Form>
</template>

<script>
import UnitChoose from '../../../stock/choose/unit.vue'

export function getDefault () {
  return {
    volume: null,
    storehouseId: null,
    storehouseUnitId: null,
  }
}

export default {
  components: { UnitChoose },
  props: {
    storehouseId: { type: Number, default: null }
  },
  data () {
    const formData = getDefault()
    if (this.storehouseId) {
      formData.storehouseId = this.storehouseId
    }
    return {
      formData,
      loading: {
        search: false
      }
    }
  },
  computed: {
    rules () {
      return {
        volume: [
          { required: true, message: '请输入数量' }
        ],
        storehouseId: [
          { required: true, message: '请选择仓库单元' }
        ],
        storehouseUnitId: [
          { required: true, message: '请选择仓库单元' }
        ]
      }
    },
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
