<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="名称" prop="name">
      <Input v-model="formData.name" placeholder="请输入名称" />
    </FormItem>
    <!-- <FormItem v-if="formData.id" label="状态" prop="exeStatus">
      <Select v-model="formData.exeStatus" placeholder="请选择状态">
        <Option v-for="item in scmGoodsPurchaseStatusList" :value="item.value" :key="item.value" border>{{ item.label }}</Option>
      </Select>
    </FormItem> -->
    <FormItem label="入库仓库" prop="storehouseId">
      <Select clearable v-model="formData.storehouseId" placeholder="请选择入库仓库">
        <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem label="计划入库时间" prop="planTime">
      <DatePicker type="datetime" :value="formData.planTime" @on-change="time => formData.planTime = time" placeholder="请选择开始时间"></DatePicker>
    </FormItem>
    <!-- <FormItem label="完成时间" prop="endTime">
      <DatePicker type="datetime" :value="formData.endTime" @on-change="time => formData.endTime = time" placeholder="请选择开始时间"></DatePicker>
    </FormItem> -->
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
export function getDefault () {
  return {
    id: null,
    name: null,
    // exeStatus: 0,
    planTime: null,
    storehouseId: null,
    // endTime: null,
    remark: null
  }
}

const rules = Object.freeze({
  name: [
    { required: true, message: '请输入名称' }
  ],
  storehouseId: [
    { required: true, message: '请选择入库仓库' }
  ]
})

export default {
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      formData: this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault(),
      rules
    }
  },
  computed: {
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : getDefault()
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
