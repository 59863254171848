<style scoped></style>

<template>
  <div style="height: 100%;display: flex;flex-direction: row;">
    <InorderGroup mode="exec" @reload="detailKey++" style="width: 250px; height: unset; flex: unset;" @item-choose="item => chooseData = item" />
    <InorderDetail :key="detailKey" v-if="chooseData" :inOrderGroupId="chooseData.id" style="flex: 1; width: 0;height: unset;margin-left: 0;padding: 0;" />
  </div>
</template>

<script>
import InorderGroup from '../../../../components/goods/produce/inorder/group.vue'
import InorderDetail from './index.vue'

export default {
  components: { InorderGroup, InorderDetail },
  data () {
    return {
      chooseData: null,
      detailKey: 0
    }
  }
}
</script>
