<style scoped></style>

<template>
  <Table :height="height" :data="dataList" :columns="columns" stripe :loading="loading">
    <template v-slot:goodsId="{ row }">
      <Tooltip>
        <Tag class="noborder" :color="row.goodsStatusData.color">
          {{ row.goodsName }}
        </Tag>
        <template v-slot:content>
          <div>{{ row.goodsStatusData.label }}</div>
          <div>{{ row.goodsGgxh }}</div>
          <div>{{ row.goodsCode }}</div>
        </template>
      </Tooltip>
    </template>
    <template v-slot:storehouseId="{ row }">
      {{ row.storehouseName }} / {{ row.storehouseUnitName }}
    </template>
    <template v-slot:realVolume="{ row }">
      {{ row.realVolume }}{{ row.goodsUnit || '' }}
    </template>
    <template v-slot:type="{ row }">
      <Tag v-if="row.type === 1" color="geekblue" class="noborder">入库</Tag>
      <Tag v-else-if="row.type === 2" color="purple" class="noborder">出库</Tag>
    </template>
    <template v-slot:status="{ row }">
      <Tag v-if="row.status === 0" color="green" class="noborder">计划</Tag>
      <Tag v-else-if="row.status === 1" color="blue" class="noborder">进行中</Tag>
      <Tag v-else-if="row.status === 2" color="geekblue" class="noborder">完成</Tag>
      <Tag v-else color="red" class="noborder">撤销</Tag>
    </template>
    <template v-slot:actionData="{ row }">
      {{ row.actionData.label }}
    </template>
    <template v-slot:action="{ row }">
      <Poptip v-if="row.status !== -1" transfer confirm @on-ok="$emit('repeal', row)" title="确定撤销吗？">
        <Button>撤销</Button>
      </Poptip>
    </template>
  </Table>
</template>

<script>
const columns = Object.freeze([
  { title: '货物', key: 'goodsId', ellipsis: true, tooltip: true, width: 130, slot: 'goodsId', fixed: 'left' },
  { title: '货物编码', key: 'goodsCode', ellipsis: true, tooltip: true, minWidth: 120 },
  { title: '规格型号', key: 'goodsGgxh', ellipsis: true, tooltip: true, minWidth: 120 },
  { title: '数量', key: 'realVolume', slot: 'realVolume', ellipsis: true, tooltip: true, width: 120 },
  { title: '仓库', key: 'storehouseId', slot: 'storehouseId', ellipsis: true, tooltip: true, minWidth: 140 },
  { title: '完成时间', key: 'endTime', tooltip: true, minWidth: 180 },
  { title: '处理人', key: 'userName', tooltip: true, minWidth: 100 },
  { title: '备注', key: 'remark', ellipsis: true, tooltip: true, minWidth: 160 },
  { title: '类型', key: 'type', slot: 'type', width: 80, fixed: 'right' },
  { title: '状态', key: 'status', slot: 'status', width: 80, fixed: 'right' },
  { title: '动作', key: 'actionData', slot: 'actionData', width: 80, fixed: 'right' },
  { title: '操作', key: 'action', slot: 'action', width: 90, fixed: 'right' }
])

export default {
  props: {
    height: { type: Number, default: undefined },
    dataList: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data () {
    return { columns }
  }
}
</script>
